import React, {Suspense, useEffect} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useIdleTimer} from 'react-idle-timer';
import {Toaster} from 'react-hot-toast';

import {signOut} from 'firebase/auth';
import {auth} from './app/Shared/Helper/firebase.helper';
import BackdropLoader from './app/Shared/Components/backdrop-loader/BackdropLoader';
import {checkForUser, getPlatformData} from './app/Shared/app.action';
import ErrorBoundary from './app/Shared/Components/error-boundary/ErrorBoundary';
import RedirectToWebsite from './app/Home/RedirectToWebsite';
import maintenance from './assets/maintenance.svg';

const Dashboard = React.lazy(() => import('./app/Dashboard/Dashboard'));
const Assessment = React.lazy(() => import('./app/Assessment/Assessment'));
const Report = React.lazy(() => import('./app/Report/Report'));
const Register = React.lazy(() => import('./app/Register/Register'));
const Login = React.lazy(() => import('./app/Login/Login'));
const ManageAccount = React.lazy(() =>
  import('./app/Manage-account/ManageAccount')
);

function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.appState.isLoading);

  useEffect(() => {
    dispatch(checkForUser());
    dispatch(getPlatformData());
  }, [dispatch]);

  const handleOnIdle = () => {
    signOut(auth);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 120, // 120 min
    onIdle: handleOnIdle,
    crossTab: true,
    debounce: 500
  });

  return (
    <Suspense fallback={<BackdropLoader />}>
      <Switch>
        {isLoading ? (
          <BackdropLoader />
        ) : (
          <ErrorBoundary>
            <Route exact path='/' component={RedirectToWebsite} />
            <Route path='/report' component={Report} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/login' component={Login} />
            <Route path='/assessment' component={Assessment} />
            <Route path='/register' component={Register} />
            <Route path='/manage-account' component={ManageAccount} />
          </ErrorBoundary>
        )}
      </Switch>
      <Toaster position='bottom-center' reverseOrder={false} />
      {/* maintenance page */}
      {/* <div className='maintenance-container'>
        <img src={maintenance} alt='Under maintenance' />
        <h1>We are under maintenance</h1>
      </div> */}
    </Suspense>
  );
}

export default App;
