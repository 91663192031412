import React from 'react';

const RedirectToWebsite = () => {
  React.useEffect(() => {
    window.location.replace('https://psypack.com');
  });

  return <div />;
};

export default RedirectToWebsite;
