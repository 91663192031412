import {getTotalWithoutPromo} from '../../Shared/Helper/util.helper';
import {registerConstants} from './register.constant';

const registerState = {
  user: null,
  loading: false,
  plans: null,
  waitEmailVerify: false,
  billingCountry: null,
  checkoutDetails: null,
  promoCode: null,
  referral: null,
  total: 0,
  totalWithoutPromo: 0
};

export default function (state = registerState, action) {
  switch (action.type) {
    case registerConstants.SET_PLANS:
      return {
        ...state,
        plans: action.payload
      };

    case registerConstants.SET_WAIT_EMAIL_VERIFY:
      return {
        ...state,
        waitEmailVerify: action.payload
      };

    case registerConstants.SET_IS_REGISTER_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case registerConstants.SET_BILLING_COUNTRY: {
      const fromIndia = action.payload?.toLowerCase() === 'in';
      const totalWithoutPromo = state.checkoutDetails
        ? getTotalWithoutPromo(state.checkoutDetails, fromIndia)
        : 0;
      // eslint-disable-next-line no-nested-ternary
      const totalOff = state.promoCode
        ? state.promoCode.percentOff
          ? Number(
              (
                totalWithoutPromo * (state.promoCode.percentOff / 100 || 0)
              ).toFixed(1)
            )
          : ((fromIndia
              ? state.promoCode.amountOff?.inr
              : state.promoCode.amountOff?.usd) || 0) / 100
        : 0;
      const total =
        Number(
          ((totalWithoutPromo - totalOff) * (fromIndia ? 1.18 : 1)).toFixed(1)
        ) > 0
          ? Number(
              ((totalWithoutPromo - totalOff) * (fromIndia ? 1.18 : 1)).toFixed(
                1
              )
            )
          : 0;
      return {
        ...state,
        total,
        totalWithoutPromo,
        billingCountry: action.payload
      };
    }

    case registerConstants.SET_CHECKOUT_DETAILS: {
      const fromIndia = state.billingCountry?.toLowerCase() === 'in';
      const totalWithoutPromo = action.payload
        ? getTotalWithoutPromo(action.payload, fromIndia)
        : 0;
      return {
        ...state,
        total: totalWithoutPromo,
        totalWithoutPromo,
        checkoutDetails: action.payload
      };
    }

    case registerConstants.SET_PROMO_CODE: {
      const fromIndia = state.billingCountry?.toLowerCase() === 'in';
      const totalWithoutPromo = state.checkoutDetails
        ? getTotalWithoutPromo(state.checkoutDetails, fromIndia)
        : 0;
      // eslint-disable-next-line no-nested-ternary
      const totalOff = action.payload
        ? action.payload.percentOff
          ? Number(
              (
                totalWithoutPromo * (action.payload.percentOff / 100 || 0)
              ).toFixed(1)
            )
          : ((fromIndia
              ? action.payload.amountOff?.inr
              : action.payload.amountOff?.usd) || 0) / 100
        : 0;
      const total =
        Number(
          ((totalWithoutPromo - totalOff) * (fromIndia ? 1.18 : 1)).toFixed(1)
        ) > 0
          ? Number(
              ((totalWithoutPromo - totalOff) * (fromIndia ? 1.18 : 1)).toFixed(
                1
              )
            )
          : 0;
      return {
        ...state,
        promoCode: action.payload,
        total,
        totalWithoutPromo
      };
    }

    case registerConstants.SET_REFERRAL_CODE: {
      const fromIndia = state.billingCountry?.toLowerCase() === 'in';
      const totalWithoutPromo = state.checkoutDetails
        ? getTotalWithoutPromo(state.checkoutDetails, fromIndia)
        : 0;
      // eslint-disable-next-line no-nested-ternary
      const totalOff = action.payload
        ? action.payload.percentOff
          ? Number(
              (
                totalWithoutPromo * (action.payload.percentOff / 100 || 0)
              ).toFixed(1)
            )
          : ((fromIndia
              ? action.payload.amountOff?.inr
              : action.payload.amountOff?.usd) || 0) / 100
        : 0;
      const total =
        Number(
          ((totalWithoutPromo - totalOff) * (fromIndia ? 1.18 : 1)).toFixed(1)
        ) > 0
          ? Number(
              ((totalWithoutPromo - totalOff) * (fromIndia ? 1.18 : 1)).toFixed(
                1
              )
            )
          : 0;
      if (state.promoCode) {
        return {
          ...state,
          referral: action.payload
        };
      }
      return {
        ...state,
        promoCode: action.payload,
        referral: action.payload,
        total,
        totalWithoutPromo
      };
    }

    default:
      return state;
  }
}
