import {IconButton} from '@material-ui/core';
import {ArrowBack, Menu} from '@material-ui/icons';
import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import psypack from '../../../../assets/psypack.svg';
import psypackIcon from '../../../../assets/PsyPackLogo.svg';
import styles from './logoContainer.module.scss';

export default function Logo({handleSidebar}) {
  const client = useRouteMatch('/dashboard/client/:id');
  const history = useHistory();
  const dashboard = useRouteMatch('/dashboard');
  const catalogue = useRouteMatch('/dashboard/assessment/catalogue/:id');
  const keyGraph = useRouteMatch(
    '/dashboard/client/:clientId/key-graph/:assessmentId'
  );
  const assessment = useRouteMatch('/assessment');
  const report = useRouteMatch('/report');

  return (
    <div className={styles.logoContainer}>
      {dashboard &&
        handleSidebar &&
        (client || catalogue ? (
          <div className={styles.actionButton}>
            <IconButton
              color='inherit'
              aria-label='back'
              edge='start'
              onClick={() => {
                if (client) history.push('/dashboard/client');
                if (catalogue) history.push('/dashboard/assessment/catalogue');
                if (keyGraph)
                  history.push(`/dashboard/client/${keyGraph.params.clientId}`);
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>
        ) : (
          <div className={styles.actionButton}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleSidebar}
            >
              <Menu />
            </IconButton>
          </div>
        ))}
      <div className={styles.logo}>
        <div className={styles.psypack}>
          {assessment || report ? (
            <img
              className={styles.small_logo}
              src={psypackIcon}
              alt='PsyPack'
            />
          ) : (
            <img className={styles.large_logo} src={psypack} alt='PsyPack' />
          )}
        </div>
      </div>
    </div>
  );
}
