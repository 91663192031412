import {reportConstants} from './report.constant';

const reportState = {
  report: [],
  isValid: null,
  details: {},
  practitionerNotes: '',
  currentPackage: {}
};

// eslint-disable-next-line default-param-last
export default (state = reportState, action) => {
  switch (action.type) {
    case reportConstants.SET_REPORT:
      return {
        ...state,
        report: action.report
      };
    case reportConstants.SET_VALIDITY:
      return {
        ...state,
        isValid: action.validity
      };
    case reportConstants.SET_PRACTITIONER_NOTES:
      return {
        ...state,
        practitionerNotes: action.payload
      };
    case reportConstants.SET_REPORT_DETAILS:
      return {
        ...state,
        details: action.payload
      };
    case reportConstants.SET_CURR_PACKAGE:
      return {
        ...state,
        currentPackage: action.payload
      };
    default:
      return state;
  }
};
