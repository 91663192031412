import {initializeApp} from 'firebase/app';
import {getStorage} from 'firebase/storage';
import {initializeFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();
const firestore = initializeFirestore(firebaseApp, {});
const storage = getStorage();

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_KEY),
  isTokenAutoRefreshEnabled: true
});

export {auth, firestore, storage, firebaseApp, appCheck};
