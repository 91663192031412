import {assessmentConstants, lastActions} from './assessment.constant';

const assessmentState = {
  isLoading: true,
  startTimestamp: Date.now(),
  questionnaire: [],
  bart: {total: 0, last: 0},
  demographics: null,
  qi: '',
  currentSection: 0,
  currentSubSection: 0,
  psychologist: '',
  allSubjects: [],
  subjectDemographics: null,
  dropdownValues: {
    countries: [],
    education: [],
    relationship_status: []
  },
  stateDropdown: {},
  cityDropdown: {},
  isValid: true,
  assessmentHelpData: {},
  assessmentData: {},
  settings: {},
  userLocation: null,
  branding: null,
  lastAction: lastActions.NEXT
};

const updateQuestionnaire = (array = [], payload) => {
  const {index, key, value} = payload;

  return array.map((item, i) => {
    if (i !== index) return item;
    return {
      ...item,
      [key]: value
    };
  });
};

export default (state = assessmentState, action) => {
  switch (action.type) {
    case assessmentConstants.SET_IS_ASSESSMENT_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case assessmentConstants.SET_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: action.payload
      };

    case assessmentConstants.SET_QI:
      return {
        ...state,
        qi: action.payload
      };

    case assessmentConstants.UPDATE_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: updateQuestionnaire(state.questionnaire, action.payload)
      };

    case assessmentConstants.SET_DROPDOWN:
      return {
        ...state,
        dropdownValues: action.payload
      };

    case assessmentConstants.SET_IS_ASSESSMENT_VALID:
      return {
        ...state,
        isValid: action.payload
      };

    case assessmentConstants.SET_ASSESSMENT_HELP_DATA:
      return {
        ...state,
        assessmentHelpData: action.payload
      };

    case assessmentConstants.SET_ASSESSMENT_DATA:
      return {
        ...state,
        assessmentData: action.payload
      };

    case assessmentConstants.SET_ASSESSMENT_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };

    case assessmentConstants.SET_DEMOGRAPHICS:
      return {
        ...state,
        demographics: action.payload
      };

    case assessmentConstants.SET_ASSESSMENT_BRANDING:
      return {
        ...state,
        branding: action.payload
      };

    case assessmentConstants.SET_SUBJECT_DEMOGRAPHICS:
      return {
        ...state,
        subjectDemographics: action.payload
      };

    case assessmentConstants.SET_ALL_SUBJECTS:
      return {
        ...state,
        allSubjects: action.payload
      };

    case assessmentConstants.UPDATE_DEMOGRAPHICS:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          [action.payload.field]: action.payload.value
        }
      };

    case assessmentConstants.SET_USER_LOCATION:
      return {
        ...state,
        userLocation: action.payload
      };

    case assessmentConstants.SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: action.payload
      };

    case assessmentConstants.SET_CURRENT_SUBSECTION:
      return {
        ...state,
        currentSubSection: action.payload
      };

    case assessmentConstants.SET_START_TIMESTAMP:
      return {
        ...state,
        startTimestamp: action.payload
      };

    case assessmentConstants.SET_ANSWER: {
      const questionnaire = [...state.questionnaire];
      questionnaire[action.payload.section].body[
        action.payload.subSection
      ].answer = action.payload.answer;
      return {
        ...state,
        questionnaire
      };
    }
    case assessmentConstants.SET_BART:
      return {
        ...state,
        bart: action.payload
      };

    case assessmentConstants.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.payload
      };

    default:
      return state;
  }
};
