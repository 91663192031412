import dashboardConstants from './dashboard.constant';

const dashboardState = {
  availableAssessments: null,
  practitionerDetails: null,
  allPackages: null,
  currPackage: null,
  searchKey: null,
  assessmentHistory: {
    allHistory: [],
    hasMore: true,
    lastDoc: null
  },
  catalogue: [],
  notifyInitiation: false,
  apiCredentials: null,
  planInfo: {
    currCycleStart: null,
    remainingAssessments: 0,
    totalAssessments: 0
  }
};

export default (state = dashboardState, action) => {
  switch (action.type) {
    case dashboardConstants.SET_ALL_ASSESSMENTS:
      return {
        ...state,
        allAssessments: action.payload
      };
    case dashboardConstants.SET_ALL_PACKAGES:
      return {
        ...state,
        allPackages: action.payload
      };
    case dashboardConstants.SET_AVAILABLE_ASSESSMENTS:
      return {
        ...state,
        availableAssessments: action.payload
      };
    case dashboardConstants.SET_CURR_PACKAGE:
      return {
        ...state,
        currPackage: action.payload
      };

    case dashboardConstants.SET_ASSESSMENT_HISTORY_INIT:
      return {
        ...state,
        assessmentHistory: {
          ...state.assessmentHistory,
          allHistory: action.payload
        }
      };

    case dashboardConstants.ADD_ASSESSMENT_HISTORY:
      return {
        ...state,
        assessmentHistory: {
          ...state.assessmentHistory,
          allHistory: [...state.assessmentHistory.allHistory, ...action.payload]
        }
      };

    case dashboardConstants.UPDATE_CLIENT_ASSESSMENT_HISTORY: {
      const {allHistory} = state.assessmentHistory;
      allHistory.forEach((clientAssessment, index) => {
        if (clientAssessment.id === action.payload.id) {
          allHistory[index] = action.payload;
        }
      });
      return {
        ...state,
        assessmentHistory: {
          ...state.assessmentHistory,
          allHistory: [...allHistory]
        }
      };
    }

    case dashboardConstants.UPDATE_REPEATING_SCHEDULE: {
      const {allHistory} = state.assessmentHistory;
      allHistory.forEach((clientAssessment, index) => {
        if (clientAssessment.repeating_id === action.payload.id) {
          allHistory[index].repeatSchedule = action.payload;
        }
      });
      return {
        ...state,
        assessmentHistory: {
          ...state.assessmentHistory,
          allHistory: [...allHistory]
        }
      };
    }

    case dashboardConstants.SET_LAST_HISTORY_DOC:
      return {
        ...state,
        assessmentHistory: {
          ...state.assessmentHistory,
          lastDoc: action.payload
        }
      };

    case dashboardConstants.SET_HAS_MORE_HISTORY:
      return {
        ...state,
        assessmentHistory: {
          ...state.assessmentHistory,
          hasMore: action.payload
        }
      };

    case dashboardConstants.SET_CATALOGUE:
      return {
        ...state,
        catalogue: action.catalogue
      };

    case dashboardConstants.NOTIFY_INITIATION:
      return {
        ...state,
        notifyInitiation: action.payload
      };

    case dashboardConstants.SET_API_CREDENTIALS:
      return {
        ...state,
        apiCredentials: action.payload
      };

    case dashboardConstants.UPDATE_SELF_ASSESSMENT_HISTORY: {
      const {allHistory} = state.assessmentHistory;
      const updatedHistory = allHistory.map((history) =>
        history.id === action.payload.id ? action.payload : history
      );
      return {
        ...state,
        assessmentHistory: {
          ...state.assessmentHistory,
          allHistory: updatedHistory
        }
      };
    }

    case dashboardConstants.SET_PLAN_INFO:
      return {
        ...state,
        planInfo: action.payload
      };

    case dashboardConstants.SET_PRACTITIONER_DETAILS:
      return {
        ...state,
        practitionerDetails: action.payload
      };

    case dashboardConstants.SET_CANCEL_AT_PERIOD_END:
      return {
        ...state,
        planInfo: {
          ...state.planInfo,
          cancelAtPeriodEnd: action.payload
        }
      };

    case dashboardConstants.SET_SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };

    case dashboardConstants.RESET_DASHBOARD_STATE:
      return dashboardState;

    default:
      return state;
  }
};
