import {appConstants} from './app.constant';

const appState = {
  isLoading: true,
  user: null,
  userType: 1,
  isPractitioner: false,
  disableUserTypeToggle: true,
  allAssessments: null,
  allPackages: null,
  constants: null
};

export default function (state = appState, action) {
  switch (action.type) {
    case appConstants.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case appConstants.SET_USER:
      return {
        ...state,
        user: action.payload
      };

    case appConstants.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload
      };

    case appConstants.SET_IS_PRACTITIONER:
      return {
        ...state,
        isPractitioner: action.payload
      };

    case appConstants.DISABLE_USER_TYPE_TOGGLE:
      return {
        ...state,
        disableUserTypeToggle: action.payload
      };

    case appConstants.UPDATE_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };

    case appConstants.SET_ALL_ASSESSMENTS:
      return {
        ...state,
        allAssessments: action.payload
      };

    case appConstants.SET_ALL_PACKAGES:
      return {
        ...state,
        allPackages: action.payload
      };

    case appConstants.SET_CONSTANTS:
      return {
        ...state,
        constants: action.payload
      };

    case appConstants.SET_PAY_PER_USE_ASSESSMENTS:
      return {
        ...state,
        payPerUseAssessments: action.payload
      };

    default:
      return state;
  }
}
