import clientConstant from './client.constant';

const clientState = {
  clientsList: [],
  lastClientDoc: null,
  hasMoreClients: false,
  clientDetails: {},
  subjectDetails: {},
  clientAssessments: [],
  hasMoreClientAssessments: false,
  lastClientAssessmentDoc: null,
  clientAssessmentKeyMetric: {},
  clientSubjectAssessmentKeyMetric: {},
  keyGraphData: null,
  searchKey: null
};

export default function (state = clientState, action) {
  switch (action.type) {
    case clientConstant.SET_CLIENTS:
      return {
        ...state,
        clientsList: action.payload
      };

    case clientConstant.ADD_CLIENTS:
      return {
        ...state,
        clientsList: [...state.clientsList, ...action.payload]
      };

    case clientConstant.SET_HAS_MORE_CLIENTS:
      return {
        ...state,
        hasMoreClients: action.payload
      };

    case clientConstant.SET_LAST_CLIENT_DOC:
      return {
        ...state,
        lastClientDoc: action.payload
      };

    case clientConstant.SET_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: action.payload
      };

    case clientConstant.SET_SUBJECT_DETAILS:
      return {
        ...state,
        subjectDetails: action.payload
      };

    case clientConstant.SET_CLIENT_ASSESSMENTS:
      return {
        ...state,
        clientAssessments: action.payload
      };

    case clientConstant.SET_CLIENT_SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };

    case clientConstant.ADD_CLIENT_ASSESSMENTS:
      return {
        ...state,
        clientAssessments: [...state.clientAssessments, ...action.payload]
      };

    case clientConstant.UPDATE_CLIENT_ASSESSMENT: {
      const {clientAssessments} = state;
      clientAssessments.forEach((clientAssessment, index) => {
        if (clientAssessment.id === action.payload.id) {
          clientAssessments[index] = action.payload;
        }
      });
      return {
        ...state,
        clientAssessments: [...clientAssessments]
      };
    }

    case clientConstant.UPDATE_CLIENT_REPEATING_SCHEDULE: {
      const allHistory = state.clientAssessments;
      allHistory.forEach((clientAssessment, index) => {
        if (clientAssessment.repeating_id === action.payload.id) {
          allHistory[index].repeatSchedule = action.payload;
        }
      });
      return {
        ...state,
        clientAssessments: [...allHistory]
      };
    }

    case clientConstant.SET_HAS_MORE_CLIENT_ASSESSMENTS:
      return {
        ...state,
        hasMoreClientAssessments: action.payload
      };

    case clientConstant.SET_LAST_CLIENT_ASSESSMENT_DOC:
      return {
        ...state,
        lastClientAssessmentDoc: action.payload
      };

    case clientConstant.RESET_CLIENT_STATE:
      return clientState;

    case clientConstant.SET_CLIENT_ASSESSMENT_KEY_METRIC:
      return {
        ...state,
        clientAssessmentKeyMetric: {
          ...state.clientAssessmentKeyMetric,
          [action.payload.clientId]: {
            ...(state.clientAssessmentKeyMetric[action.payload.clientId] ?? {}),
            [action.payload.assessmentId]: action.payload.data
          }
        }
      };

    case clientConstant.SET_CLIENT_SUBJECT_ASSESSMENT_KEY_METRIC:
      return {
        ...state,
        clientSubjectAssessmentKeyMetric: {
          ...state.clientSubjectAssessmentKeyMetric,
          [action.payload.clientId]: {
            ...(state.clientSubjectAssessmentKeyMetric[
              action.payload.clientId
            ] ?? {}),
            [action.payload.subjectId]: {
              ...(state.clientSubjectAssessmentKeyMetric[
                action.payload.clientId
              ]?.[action.payload.subjectId] ?? {}),
              [action.payload.assessmentId]: action.payload.data
            }
          }
        }
      };

    case clientConstant.SET_KEY_GRAPH_DATA:
      return {
        ...state,
        keyGraphData: action.payload.data
      };

    default:
      return state;
  }
}
