export const reportConstants = {
  SET_REPORT: 'set_report',
  SET_VALIDITY: 'set_validity',
  SET_REPORT_DETAILS: 'SET_REPORT_DETAILS',
  SET_PRACTITIONER_NOTES: 'SET_PRACTITIONER_NOTES',
  SET_CURR_PACKAGE: 'SET_CURR_PACKAGE'
};
export const structureConstants = {
  SECTION: 'section',
  HEADING: 'heading',
  TEXT: 'text',
  TABLE: 'table',
  POINTS: 'points',
  BAR_CHART: 'barchart',
  SCATTER_CHART: 'scatterchart',
  RADAR_CHART: 'radarchart',
  LEGENDS: 'legends',
  IMAGE: 'image',
  SINGLE_BAR: 'singleBar',
  SCORE_POINTER: 'scorePointer',
  STYLED_TEXT: 'styledText'
};
