import React, {useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import {Hidden, IconButton, Grow, MenuList, Paper} from '@material-ui/core';
import {KeyboardArrowDown} from '@material-ui/icons';
import {signOut} from 'firebase/auth';
import {setUserType} from '../../app.action';
import {userTypeEnum} from '../../Constants/appConstants';
import style from './TopNav.module.scss';
import {auth} from '../../Helper/firebase.helper';
import Account from '../account/Account';
import LogoContainer from '../logoContainer/LogoContainer';

const TopNav = ({
  showUser,
  enableBreadCrumb,
  handleSidebar,
  showUserType,
  brandingData = {},
  showPractitionerLogo
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userType = useSelector((state) => state.appState.userType);
  const assessmentData = useSelector(
    (state) => state.assessment.assessmentData
  );
  const [open, setOpen] = React.useState(false);

  const disableUserTypeToggle = useSelector(
    (state) => state.appState.disableUserTypeToggle
  );

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const selectOption = (event, option) => {
    handleClose(event);
    if (option === 'changeUserType') {
      dispatch(
        setUserType(
          userType === userTypeEnum.PRACTITIONER
            ? userTypeEnum.CLIENT
            : userTypeEnum.PRACTITIONER
        )
      );
    } else if (option === 'logout') {
      signOut(auth);
    }
  };

  return (
    <div className={style.navWrapper}>
      <LogoContainer handleSidebar={handleSidebar} />
      <div className={style.contentContainer}>
        {enableBreadCrumb && userType === userTypeEnum.PRACTITIONER && (
          <div className={style.breadCrumb}>
            <p
              className={
                location.pathname.includes('create-account') ? style.active : ''
              }
            >
              1. Create account
            </p>
            <p
              className={
                location.pathname.includes('confirm-plan') ? style.active : ''
              }
            >
              2. Confirm plan
            </p>
            <p
              className={
                location.pathname.includes('checkout') ? style.active : ''
              }
            >
              3. Checkout
            </p>
            <p
              className={
                location.pathname.includes('start-plan') ? style.active : ''
              }
            >
              4. Start plan
            </p>
          </div>
        )}
      </div>
      {(showUser || showUserType) && !showPractitionerLogo && (
        <div className={style.dropdown}>
          <Hidden xsDown implementation='css'>
            <Account {...{showUser, showUserType}} />
          </Hidden>
          {showUser || !disableUserTypeToggle ? (
            <IconButton onClick={handleToggle}>
              <KeyboardArrowDown ref={anchorRef} />
            </IconButton>
          ) : (
            <div />
          )}
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role='button'
            transition
            disablePortal
            placement='bottom-end'
            style={{
              minWidth: '150px'
            }}
          >
            {({TransitionProps}) => (
              <Grow
                {...TransitionProps}
                style={{transformOrigin: 'center top'}}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                    >
                      {!disableUserTypeToggle && (
                        <MenuItem
                          onClick={(e) => selectOption(e, 'changeUserType')}
                        >
                          {userType === userTypeEnum.PRACTITIONER
                            ? 'Client'
                            : 'Practitioner'}
                        </MenuItem>
                      )}
                      {showUser && (
                        <MenuItem onClick={(e) => selectOption(e, 'logout')}>
                          Logout
                        </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
      {showPractitionerLogo &&
      !assessmentData.freePlanPractitioner &&
      brandingData.brandLogo ? (
        <div className={style.org_logo}>
          <img
            src={brandingData?.brandLogo || ''}
            alt={brandingData?.businessName || ''}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TopNav;
