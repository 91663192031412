import {useState, useEffect, useRef, useLayoutEffect} from 'react';

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

export const customSymbolCircle = {
  draw: (context, size) => {
    const r = Math.sqrt((2 * size) / Math.PI);
    const origin = (4 * r) / (3 * Math.PI); // the orgin of the circle, not of the symbol
    context.arc(0, -origin, r, Math.PI, 2 * Math.PI, true);
    context.closePath();
  }
};

export const customSymbolTri = {
  draw: (context, size) => {
    const s = Math.sqrt(size / 4);
    context.moveTo(-s, s * 2);
    context.lineTo(s * 2, -s);
    context.lineTo(-s, -s);
    context.closePath();
  }
};

export const customSymbolTri2 = {
  draw(context, size) {
    const s = Math.sqrt(size / 4);
    context.moveTo(s * 2, s * 2);
    context.lineTo(s * 2, -s);
    context.lineTo(-s, -s);
    context.closePath();
  }
};

export const customSymbolRectangle = {
  draw(context, size) {
    const s = Math.sqrt(size / 6);
    context.moveTo(-s * 2, s * 2);
    context.lineTo(s * 3, s * 2);
    context.lineTo(s * 3, -s);
    context.lineTo(-s * 2, -s);
    context.closePath();
  }
};

export const customSymbolArrow = {
  draw(context, size) {
    const s = Math.sqrt(size / 6);
    context.moveTo(-s * 2, s * 2);
    context.lineTo(s / 3, s);
    context.lineTo(s * 3, s * 2);
    context.lineTo(s / 3, -s);
    context.closePath();
  }
};

export const customSymbolArrow2 = {
  draw(context, size) {
    const s = Math.sqrt(size / 6);
    context.moveTo(s / 3, -s);
    context.lineTo(s / 3, s);
    context.lineTo(s * 3, s * 2);
    context.lineTo(-s * 2, s * 2);
    context.closePath();
  }
};

export const customSymbolTri3 = {
  draw(context, size) {
    const s = Math.sqrt(size / 5);
    context.moveTo(s * 2, s * 2);
    context.lineTo(s * 2, -s * 2);
    context.lineTo(-s, s * 2);
    context.lineTo(-s, -s * 2);
    context.closePath();
  }
};

export const randomString = (len, an) => {
  an = an && an.toLowerCase();
  let str = '';
  let i = 0;
  const min = an == 'a' ? 10 : 0;
  const max = an == 'n' ? 10 : 62;
  for (; i++ < len; ) {
    let r = (Math.random() * (max - min) + min) << 0;
    str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
  }
  return str;
};

export const getFileExtension = (fileName) => {
  return fileName.split('.').pop().trim();
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getTotalWithoutPromo = (checkoutDetails, fromIndia = false) => {
  return Number(
    (fromIndia
      ? checkoutDetails.basePlan.inrPrice +
        (checkoutDetails.additionalPlans.length
          ? checkoutDetails.additionalPlans.reduce(
              (sum, plan) =>
                sum + plan.inrPrice * (Number(plan.quantity) / plan.divideBy),
              0
            )
          : 0)
      : checkoutDetails.basePlan.price +
        (checkoutDetails.additionalPlans.length
          ? checkoutDetails.additionalPlans.reduce(
              (sum, plan) =>
                sum + plan.price * (Number(plan.quantity) / plan.divideBy),
              0
            )
          : 0)
    ).toFixed(2)
  );
};

export const useElementOnScreen = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
};
