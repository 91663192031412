import * as d3 from 'd3';
import {
  customSymbolCircle,
  customSymbolTri,
  customSymbolTri2,
  customSymbolTri3,
  customSymbolArrow,
  customSymbolArrow2,
  customSymbolRectangle
} from '../Helper/util.helper';

export const userTypeEnum = {
  CLIENT: 0,
  PRACTITIONER: 1
};

export const initiationTypeEnum = {
  PRACTITIONER: 0,
  PRACTITIONER_API: 1,
  SELF_ASSESSMENT: 2
};

export const d3Scales = {
  LINEAR: 0,
  POINT: 1
};

export const shapes = {
  0: d3.symbolCircle,
  1: d3.symbolTriangle,
  2: d3.symbolCross,
  3: d3.symbolDiamond,
  4: d3.symbolSquare,
  5: d3.symbolStar,
  6: d3.symbolWye,
  7: customSymbolCircle,
  8: customSymbolTri,
  9: customSymbolTri2,
  10: customSymbolRectangle,
  11: customSymbolTri3,
  12: customSymbolArrow,
  13: customSymbolArrow2
};

export const planIdEnum =
  process.env.REACT_APP_NODE_ENVIRONMENT === 'staging'
    ? {
        professional: 'YptiHy6sgNgEjNeMYXypIe5pi',
        free: 'RAS43SADsfs',
        additionalAdministrations: 'j43fikbNopKDepArsdASf',
        additionalSeats: 'i7pVS26EHn4QYVwApi9Q'
      }
    : {
        professional: 'YptiHy6sgNgEjNeMYXypIe5pi',
        free: 'RhQXMxTXMxWV2mELY4e9',
        additionalAdministrations: 'j43fikbNopKDepArsdASf',
        additionalSeats: 'i7pVS26EHn4QYVwApi9Q'
      };

export const structureConstants = {
  SECTION: 'section',
  HEADING: 'heading',
  TEXT: 'text',
  TABLE: 'table',
  POINTS: 'points',
  BAR_CHART: 'barchart',
  SCATTER_CHART: 'scatterchart',
  RADAR_CHART: 'radarchart',
  LEGENDS: 'legends',
  IMAGE: 'image',
  SINGLE_BAR: 'singleBar',
  SCORE_POINTER: 'scorePointer',
  STYLED_TEXT: 'styledText',
  CARTESIAN_CHART: 'cartesianChart'
};
