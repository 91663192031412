import StackdriverErrorReporter from 'stackdriver-errors-js';

// eslint-disable-next-line import/no-mutable-exports
let errorHandler;

if (process.env.NODE_ENV === 'production') {
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: process.env.REACT_APP_REPORTING_API_KEY,
    projectId: process.env.REACT_APP_PROJECT_ID
  });
} else {
  errorHandler = {report: console.error};
}

export default errorHandler;
