export const assessmentConstants = {
  SET_QUESTIONNAIRE: 'SET_QUESTIONNAIRE',
  UPDATE_QUESTIONNAIRE: 'update_questionnaire',
  SET_QI: 'set_qi',
  SET_DROPDOWN: 'SET_DROPDOWN',
  SET_IS_ASSESSMENT_VALID: 'SET_IS_ASSESSMENT_VALID',
  SET_ASSESSMENT_HELP_DATA: 'SET_ASSESSMENT_HELP_DATA',
  SET_ASSESSMENT_DATA: 'SET_ASSESSMENT_DATA',
  SET_ASSESSMENT_SETTINGS: 'SET_ASSESSMENT_SETTINGS',
  SET_IS_ASSESSMENT_LOADING: 'SET_IS_ASSESSMENT_LOADING',
  SET_DEMOGRAPHICS: 'SET_DEMOGRAPHICS',
  UPDATE_DEMOGRAPHICS: 'UPDATE_DEMOGRAPHICS',
  SET_USER_LOCATION: 'SET_USER_LOCATION',
  SET_CURRENT_SECTION: 'SET_CURRENT_SECTION',
  SET_CURRENT_SUBSECTION: 'SET_CURRENT_SUBSECTION',
  SET_ANSWER: 'SET_ANSWER',
  SET_BART: 'SET_BART',
  SET_LAST_ACTION: 'SET_LAST_ACTION',
  SET_START_TIMESTAMP: 'SET_START_TIMESTAMP',
  SET_ASSESSMENT_BRANDING: 'SET_ASSESSMENT_BRANDING',
  SET_SUBJECT_DEMOGRAPHICS: 'SET_SUBJECT_DEMOGRAPHICS',
  SET_ALL_SUBJECTS: 'SET_ALL_SUBJECTS'
};
export const questionnaireConstants = {
  INSTRUCTION: 'instruction',
  QUESTIONS: 'questions'
  // PROMPT: 'question_instruction'
};

export const questionType = {
  BASIC_QUESTION: 'basic_question',
  BART_QUESTION: 'BALLOON_QUESTION',
  CONDITIONAL_QUESTION: 'conditional_question',
  FORM_QUESTION: 'form_question',
  CORSI_BLOCK: 'CORSI_BLOCK'
};

export const lastActions = {
  BACK: 'BACK',
  NEXT: 'NEXT'
};

export const optionTypes = {
  RADIO: 1,
  CHECKBOX: 2
};
