import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import PsyPackLogo from '../../../../assets/PsyPackLogo.svg';

const BackdropLoader = () => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  }));

  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress size={120} color='primary' />
      <img
        src={PsyPackLogo}
        alt='PsyPack'
        style={{width: '50px', position: 'absolute'}}
      />
    </Backdrop>
  );
};

export default BackdropLoader;
