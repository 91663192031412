const clientConstant = {
  SET_CLIENTS: 'SET_CLIENTS',
  SET_LAST_CLIENT_DOC: 'SET_LAST_CLIENT_DOC',
  ADD_CLIENTS: 'ADD_CLIENTS',
  SET_HAS_MORE_CLIENTS: 'SET_HAS_MORE_CLIENTS',
  SET_CLIENT_DETAILS: 'SET_CLIENT_DETAILS',
  SET_HAS_MORE_CLIENT_ASSESSMENTS: 'SET_HAS_MORE_CLIENT_ASSESSMENTS',
  SET_LAST_CLIENT_ASSESSMENT_DOC: 'SET_LAST_CLIENT_ASSESSMENT_DOC',
  SET_CLIENT_ASSESSMENTS: 'SET_CLIENT_ASSESSMENTS',
  ADD_CLIENT_ASSESSMENTS: 'ADD_CLIENT_ASSESSMENTS',
  RESET_CLIENT_STATE: 'RESET_CLIENT_STATE',
  SET_CLIENT_ASSESSMENT_KEY_METRIC: 'SET_CLIENT_ASSESSMENT_KEY_METRIC',
  SET_KEY_GRAPH_DATA: 'SET_KEY_GRAPH_DATA',
  UPDATE_CLIENT_ASSESSMENT: 'UPDATE_CLIENT_ASSESSMENT',
  UPDATE_CLIENT_REPEATING_SCHEDULE: 'UPDATE_CLIENT_REPEATING_SCHEDULE',
  SET_CLIENT_SUBJECT_ASSESSMENT_KEY_METRIC:
    'SET_CLIENT_SUBJECT_ASSESSMENT_KEY_METRIC',
  SET_SUBJECT_DETAILS: 'SET_SUBJECT_DETAILS',
  SET_CLIENT_SEARCH_KEY: 'SET_CLIENT_SEARCH_KEY'
};

export default clientConstant;
