export const appConstants = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_USER: 'SET_USER',
  SET_USER_TYPE: 'SET_USER_TYPE',
  DISABLE_USER_TYPE_TOGGLE: 'DISABLE_USER_TYPE_TOGGLE',
  SET_IS_PRACTITIONER: 'SET_IS_PRACTITIONER',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  SET_ALL_PACKAGES: 'SET_ALL_PACKAGES',
  SET_ALL_ASSESSMENTS: 'SET_ALL_ASSESSMENTS',
  SET_CONSTANTS: 'SET_CONSTANTS',
  SET_PAY_PER_USE_ASSESSMENTS: 'SET_PAY_PER_USE_ASSESSMENTS'
};
