import {combineReducers} from 'redux';

import appState from './app.reducer';
import dashboardState from '../Dashboard/Shared/combineDashboardReducers';
import registerState from '../Register/shared/register.reducer';
import assessmentState from '../Assessment/Shared/assessment.reducer';
import reportState from '../Report/Shared/report.reducer';

export default combineReducers({
  appState,
  dashboardState,
  register: registerState,
  assessment: assessmentState,
  report: reportState
});
