import React from 'react';
import {useSelector} from 'react-redux';
import {userTypeEnum} from '../../Constants/appConstants';
import styles from './account.module.scss';

export default function Account({showUser = false, showUserType = true}) {
  const user = useSelector((state) => state.appState.user);
  const userType = useSelector((state) => state.appState.userType);
  return (
    <div className={styles.userAccount}>
      {showUser && user && (
        <span className={styles.userEmail}>{user.email}</span>
      )}
      {showUserType && (
        <span className={styles.userRole}>
          {userType !== userTypeEnum.PRACTITIONER ? 'Client' : 'Practitioner'}
        </span>
      )}
    </div>
  );
}
