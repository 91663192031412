const dashboardConstants = {
  SET_AVAILABLE_ASSESSMENTS: 'SET_AVAILABLE_ASSESSMENTS',
  SET_CURR_PACKAGE: 'SET_CURR_PACKAGE',
  SET_ALL_PACKAGES: 'SET_ALL_PACKAGES',
  SET_ASSESSMENT_HISTORY_INIT: 'SET_ASSESSMENT_HISTORY_INIT',
  ADD_ASSESSMENT_HISTORY: 'ADD_ASSESSMENT_HISTORY',
  SET_HAS_MORE_HISTORY: 'SET_HAS_MORE_HISTORY',
  SET_LAST_HISTORY_DOC: 'SET_LAST_HISTORY_DOC',
  SET_CATALOGUE: 'SET_CATALOGUE',
  RESET_DASHBOARD_STATE: 'RESET_DASHBOARD_STATE',
  NOTIFY_INITIATION: 'NOTIFY_INITIATION',
  SET_API_CREDENTIALS: 'SET_API_CREDENTIALS',
  SET_PLAN_INFO: 'SET_PLAN_INFO',
  SET_CANCEL_AT_PERIOD_END: 'SET_CANCEL_AT_PERIOD_END',
  SET_PRACTITIONER_DETAILS: 'SET_PRACTITIONER_DETAILS',
  UPDATE_CLIENT_ASSESSMENT_HISTORY: 'UPDATE_CLIENT_ASSESSMENT_HISTORY',
  UPDATE_REPEATING_SCHEDULE: 'UPDATE_REPEATING_SCHEDULE',
  UPDATE_SELF_ASSESSMENT_HISTORY: 'UPDATE_SELF_ASSESSMENT_HISTORY',
  SET_SEARCH_KEY: 'SET_SEARCH_KEY'
};

export default dashboardConstants;
