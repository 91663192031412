import React from 'react';
import {Button} from '@material-ui/core';

import TopNav from '../TopNav/TopNav';
import style from './ErrorBoundary.module.scss';
import errorHandler from '../../Helper/errorReporter.helper';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    errorHandler.report(error);
    return {hasError: error};
  }

  componentDidCatch(error, errorInfo) {
    errorHandler.report(error);
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={style.error_wrapper}>
          <TopNav />
          <div className={style.error_container}>
            <h1>Error</h1>
            <p className={style.error}>Oops, something went wrong.</p>
            <p>{this.state.hasError.toString()}</p>
            <Button
              variant='contained'
              className={style.button}
              color='primary'
              href='https://psypack.com'
            >
              take me home
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
