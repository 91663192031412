import {
  getIdToken,
  getIdTokenResult,
  onAuthStateChanged,
  onIdTokenChanged
} from 'firebase/auth';
import {doc, getDoc} from 'firebase/firestore';
import {appConstants} from './app.constant';
import {userTypeEnum} from './Constants/appConstants';
import {auth, firestore} from './Helper/firebase.helper';

export const setIsPractitioner = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_IS_PRACTITIONER,
    payload
  });
};

export const setConstants = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_CONSTANTS,
    payload
  });
};

export const setUserType = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_USER_TYPE,
    payload
  });
};

export const setIsLoading = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_IS_LOADING,
    payload
  });
};

export const setUser = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_USER,
    payload
  });
};

export const setAllAssessments = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_ALL_ASSESSMENTS,
    payload
  });
};

export const setAllPackages = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_ALL_PACKAGES,
    payload
  });
};

export const setPayPerUseAssessments = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_PAY_PER_USE_ASSESSMENTS,
    payload
  });
};

export const checkForUser = () => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    dispatch(setUser(user));
    localStorage.removeItem('token');
    if (user && auth.currentUser) {
      getIdTokenResult(user)
        .then((idTokenResult) => {
          if (idTokenResult.claims.userType == userTypeEnum.PRACTITIONER) {
            dispatch(setIsPractitioner(true));
            dispatch(setUserType(userTypeEnum.PRACTITIONER));
          } else if (idTokenResult.claims.userType == userTypeEnum.CLIENT) {
            dispatch(setIsPractitioner(false));
            dispatch(setUserType(userTypeEnum.CLIENT));
          }
          dispatch(setIsLoading(false));
        })
        .catch((error) => {
          console.log(error);
        });
      getIdToken(auth.currentUser).then((token) => {
        localStorage.setItem('token', token);
      });
    }
    dispatch(setIsLoading(false));
  });
};

export const getPlatformData = () => async (dispatch) => {
  const task = await Promise.all([
    getDoc(doc(firestore, 'platform_data', 'package_data')),
    getDoc(doc(firestore, 'platform_data', 'constants'))
  ]);
  const platformData = task[0].data();
  dispatch(setConstants(task[1].data()));
  const allAssessments = Object.keys(platformData.assessments).map(
    (assessmentId) => {
      return {
        id: assessmentId,
        ...platformData.assessments[assessmentId]
      };
    }
  );
  dispatch(
    setAllAssessments(
      allAssessments.sort((a, b) => a.short_title.localeCompare(b.short_title))
    )
  );
  const allPackages = Object.keys(platformData.psychologist_packages).map(
    (packageId) => {
      return {
        id: packageId,
        ...platformData.psychologist_packages[packageId]
      };
    }
  );
  dispatch(setAllPackages(allPackages));
  dispatch(setPayPerUseAssessments(platformData.pay_per_assessments));
};

export const disableUserTypeToggle = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.DISABLE_USER_TYPE_TOGGLE,
    payload
  });
};

export const updateProfileState = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.UPDATE_USER_PROFILE,
    payload
  });
};
